<script>
  import { signIn } from '@auth/sveltekit/client'
  import { variables } from '$lib/variables.js'
  import { page } from '$app/stores'
  import { onMount } from 'svelte'
  const { appName } = variables

  // this does an fetch request to check on the server for prerendered routes
  let signedin = $page.data.session != null
  let isUwu = false
  onMount(async () => {
    isUwu = $page.url.searchParams.get('uwu') !== null
    if (!signedin) {
      const res = await fetch('/api/prerender')
      const data = await res.json()
      signedin = data.signedin
    }
  })
</script>

<nav>
  <div class="inner">
    <a href="/" class="logo" class:uwu={isUwu}>
      {#if isUwu}
        <img src="/logo/wordmark-uwu.avif" alt={appName} height="42" />
      {:else}
        <img src="/logo/wordmark-light.svg" alt={appName} height="26" width="131" />
      {/if}
    </a>
    <ul class="nav">
      <li><a href="/#features">Features</a></li>
      <li><a href="/docs">Docs</a></li>
      <li><a href="/blog">Blog</a></li>
    </ul>
    <ul class="login">
      {#if signedin}
        <li><a href="/files" class="primary">Open app</a></li>
      {:else}
        <li><button on:click={() => signIn('auth0', { callbackUrl: '/files' })}>Login</button></li>
        <li>
          <button
            class="primary"
            on:click={() => signIn('auth0', { callbackUrl: '/files' }, { screen_hint: 'signup' })}
            >Sign up</button
          >
        </li>
      {/if}
    </ul>
  </div>
</nav>

<style lang="scss">
  nav {
    padding: 0.75rem 0.5rem;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    position: sticky;
    z-index: 5;
    top: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    @media (min-width: 500px) {
      padding: 0.75rem 1.5rem;
    }
  }
  .inner {
    margin: 0 auto;
    width: 100%;
    max-width: 1100px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.25rem;
  }
  .logo {
    font-size: 1.25rem;
    font-weight: 600;
    letter-spacing: -0.5px;
    color: #fff;
    padding: 0.5rem;
    border-radius: 4px;
  }
  .logo:focus {
    background: #e5e5e7;
  }
  .logo.uwu {
    padding: 0 0.5rem;
  }
  img {
    vertical-align: top;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 5px 0 0;
    font-size: 0;
    align-self: center;
  }
  @media (min-width: 700px) {
    .inner {
      display: flex;
    }
    ul {
      padding: 0 0.5rem;
    }
  }
  ul.nav {
    grid-column: 1 / span 2;
  }
  ul.login {
    flex: 1;
    text-align: right;
    grid-column: 2;
    grid-row: 1;
  }
  li {
    display: inline-block;
    vertical-align: top;
  }
  li a,
  li button {
    color: var(--secondary-site-text-color);
    font-weight: 600;
    font-size: 15px;
    padding: 0.375rem 0.5rem;
    border-radius: 4px;
    transition: 150ms ease all;
    border: 0;
    background: transparent;
    display: block;
    cursor: pointer;
    line-height: 1;
  }
  li a:hover,
  li a:focus,
  li button:hover,
  li button:focus {
    color: var(--site-text-color);
    background: #e5e5e7;
    text-decoration: none;
  }
  .primary {
    background: #222;
    color: #fff;
    padding: 0.5rem 0.75rem;
    margin-left: 0.75rem;
    margin-top: -2px;
  }
  .primary:hover,
  .primary:focus-within {
    background: #666;
    color: #fff;
  }
</style>
